import { TenantConfigurationModel } from "../models/tenant-configuration.model";

export class TenantConfigurationFactory {

  static toDTO(tenantConfigurationModel: TenantConfigurationModel): any {
    throw Error('not implemented yet');
  }

  static toModel(dto: any): TenantConfigurationModel {
    return {
      tenantId: dto.tenant_id,
      clientId: dto.client_id,
      directory: dto.directory,
      tenantName: dto.common_name,
      policies: dto.policies ? dto.policies.map(policy => policy.policy_id) : []
    };
  }

}
