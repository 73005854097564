import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {User} from '../../models/user.model';

export const loadPatients = createAction(
  '[User/Effect] Load Patients',
);

export const loadCoaches = createAction(
  '[User/Effect] Load Coaches',
);

export const loadNurses = createAction(
  '[User/Effect] Load Nurses',
);

export const loadDoctors = createAction(
  '[User/Effect] Load Doctors',
);

export const loadUnassignedCTMs = createAction(
  '[User/Effect] Load Unassigned CTMs',
);

export const usersLoaded = createAction(
  '[User/API] Users Loaded',
  props<{ users: User[] }>()
);

export const patientsLoaded = createAction(
  '[User/API] Patients Loaded',
  props<{ users: User[] }>()
);

export const assignPatientsToUsers = createAction(
  '[User/API] Assign patients',
  props<{userIds: number[], usersIdsToAssign: number[]}>()
);

export const assignCTMStoUsers = createAction(
  '[User/API] Assign CTMs',
  props<{userIds: number[], usersIdsToAssign: number[]}>()
);

export const addUser = createAction(
  '[User/API] Add User',
  props<{ user: User }>()
);

export const upsertUser = createAction(
  '[User/API] Upsert User',
  props<{ user: User }>()
);

export const addUsers = createAction(
  '[User/API] Add Users',
  props<{ users: User[] }>()
);

export const upsertUsers = createAction(
  '[User/API] Upsert Users',
  props<{ users: User[] }>()
);

export const updateUser = createAction(
  '[User/API] Update User',
  props<{ user: Update<User> }>()
);

export const updateUsers = createAction(
  '[User/API] Update Users',
  props<{ users: Update<User>[] }>()
);

export const deleteUser = createAction(
  '[User/API] Delete User',
  props<{ user: User}>()
);

export const deleteUsers = createAction(
  '[User/API] Delete Users',
  props<{ ids: string[] }>()
);

export const clearUsers = createAction(
  '[User/API] Clear Users'
);

export const loadAssignedUsersForUsers = createAction(
  '[User/Effect] load assigned users',
  props<{ids: number[]}>()
);

export const loadFullUser = createAction(
  '[User/Effect] Load Full User',
  props<{id: number}>()
);

export const assignRoleToUser = createAction(
  '[User/Effect] Assign role to user',
  props<{userIds: number[], roleId: number}>()
)

export const changeUserRoles = createAction(
  '[User/Effect] Change User Roles',
  props<{user: User, rolesToAdd: number [], rolesToRemove: number[]}>()
)
