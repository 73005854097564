import { Action } from '@ngrx/store';

import { AuthModel } from "../../models/auth.model";

export enum AuthActionTypes {
  AfterResetPassword = '[Auth] After Reset Password',
  Authenticate = '[Auth] Authenticate Auth',
  Authenticated = '[Auth Effect] Auth Authenticated',
  AuthNotAllowed = '[Auth Effect] Auth Not Allowed',
  ErrorEncountered = '[Auth Effect] Error Encountered',
  LogOut = '[Auth] Log Out Auth',
  LoggedOut = '[Auth Effect] Auth Logged Out',
  GetAuthData = '[Auth] Get Auth Data',
  ResetPassword = '[Auth] Reset Password'
}

export class Authenticate implements Action {
  readonly type = AuthActionTypes.Authenticate;

}

export class Authenticated implements Action {
  readonly type = AuthActionTypes.Authenticated;

  constructor(public payload: { authData: AuthModel }) { }
}

export class AuthNotAllowed implements Action {
  readonly type = AuthActionTypes.AuthNotAllowed;

}

export class ErrorEncountered implements Action {
  readonly type = AuthActionTypes.ErrorEncountered;

  constructor(public payload: { error: any }) { }
}

export class LogOut implements Action {
  readonly type = AuthActionTypes.LogOut;

}

export class LoggedOut implements Action {
  readonly type = AuthActionTypes.LoggedOut;

}

export class GetAuthData implements Action {
  readonly type = AuthActionTypes.GetAuthData;

}

export class ResetPassword implements Action {
  readonly type = AuthActionTypes.ResetPassword;
}

export class AfterResetPassword implements Action {
  readonly type = AuthActionTypes.AfterResetPassword;
}

export type AuthActions = AfterResetPassword
  | Authenticate
  | Authenticated
  | AuthNotAllowed
  | LogOut
  | LoggedOut
  | ErrorEncountered
  | GetAuthData
  | ResetPassword;


