import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CredentialState } from "./credential.reducer";

export const selectCredentialState = createFeatureSelector<CredentialState>('credential');

export const selectCredentials = createSelector(
  selectCredentialState,
  (credentialState: CredentialState) => credentialState.credentials
);

export const selectLastPage = createSelector(
  selectCredentialState,
  (credentialState: CredentialState) => credentialState.lastPage
);

export const selectLoadedAll = createSelector(selectCredentialState, state => {
  return state.loadedAll;
});

export const selectTenantConfiguration = createSelector(
  selectCredentialState,
  (credentialState: CredentialState) => credentialState.configuration
);

export const selectTenantLocations = createSelector(
  selectCredentialState,
  (credentialState: CredentialState) => credentialState.locations
);
