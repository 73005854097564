import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';

export const selectUserState = createFeatureSelector<fromUser.State>(fromUser.usersFeatureKey);

export const selectAll = createSelector(selectUserState, state => {
  return fromUser.selectAll(state)
});

export const selectLastPage = createSelector(selectUserState, state => {
  return state.lastPage;
});

export const selectLoadedAll = createSelector(selectUserState, state => {
  return state.loadedAll;
});

export const selectUser = createSelector(selectAll, (users, props: {id: number}) => {
  return users.find(user => user.id === props.id);
});

export const selectUsers = createSelector(selectAll, (users, props: {ids: number[]}) => {
  return users.filter(user => props.ids.includes(user.id));
});

export const selectPatients = createSelector(selectAll, users => users.filter(user => user.roleNames && user.roleNames.includes('Patient')));

export const selectCoaches = createSelector(selectAll, users => users.filter(user => user.roleNames && user.roleNames.includes('Coach')));

export const selectNurses = createSelector(selectAll, users => users.filter(user => user.roleNames && user.roleNames.includes('Nurse')));

export const selectDoctors = createSelector(selectAll, users => users.filter(user => user.roleNames && user.roleNames.includes('Doctor')));

export const selectUnassigned = createSelector(selectAll, users => users.filter(user => user.roleNames && user.roleNames.length === 0));
