import {CanActivate, Router} from "@angular/router";
import {Injectable} from "@angular/core";
import { TokenHelper } from "../helpers/token.helper";
import * as moment from 'moment';
import { State } from "../../core/store";
import { Store } from "@ngrx/store";
import { LogOut } from "../../core/store/auth/auth.actions";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private store: Store<State>) { }

  canActivate(): boolean {
    const token = localStorage.getItem('token');
    if (!token) {
      this.router.navigateByUrl('');
      return false;
    } else {
      const tokenData = TokenHelper.parseJwtToken(token);
      if (moment(tokenData.exp * 1000).diff(moment(), 'seconds') <= 0) {
        this.store.dispatch(new LogOut());
        return false;
      }
    }
    return true;
  }

}
