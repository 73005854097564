import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { TokenHelper } from '../../utils/helpers/token.helper';
import { LogOut } from '../../core/store/auth/auth.actions';
import { State } from '../../core/store';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private store: Store<State>) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.url.indexOf(environment.apiConstants.GATEWAY_BASE_URL) !== -1) {
      const sessionToken = localStorage.getItem('token');
      if (sessionToken) {
        const tokenData = TokenHelper.parseJwtToken(sessionToken);
        const tokenExp = moment(tokenData.exp * 1000);
        const now = moment();
        const fiveMins = 5 * 60;
        if (tokenExp.diff(now, 'seconds') < fiveMins) {
          this.store.dispatch(new LogOut());
         } else {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${sessionToken}`
            }
          });
         }
      }
    }

    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error?.status === 403 || error?.status === 401) {
            this.store.dispatch(new LogOut());
          }
          return throwError(error)
        })
      );
  }
}
