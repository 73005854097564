import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { environment } from "../../../../environments/environment";
import { AuthFactory } from "../../factory/authFactory";
import { User } from '../../models/user.model';
import { AuthService } from "../../services/auth.service";
import { UserService } from "../../services/user.service";
import {
  AfterResetPassword,
  AuthActionTypes,
  AuthNotAllowed,
  Authenticate,
  Authenticated,
  ErrorEncountered,
  GetAuthData,
  LogOut,
  LoggedOut,
  ResetPassword
} from "./auth.actions";

@Injectable()
export class AuthEffects {

  constructor(private actions$: Actions, private authService: AuthService, private userService: UserService) { }

  @Effect()
  login$ = this.actions$.pipe(
    ofType<Authenticate>(AuthActionTypes.Authenticate),
    switchMap(() => this.authService.login()
      .pipe(
        tap((token: string) => localStorage.setItem('token', token)),
        switchMap(() => this.userService.getLoginUser()
          .pipe(
            tap((user: User) => localStorage.setItem('user', JSON.stringify(user))),
            map((user: User) => new Authenticated({
              authData: AuthFactory.toModel({
                firstName: user.first,
                lastName: user.last,
                tenantId: environment.apiConstants.AZURE_B2C_CLIENT_ID
              })
            }))
          )
        ),
        catchError(error => {
          return of(new ErrorEncountered({ error }));
        })
      )),
    catchError(error => {
      return of(new ErrorEncountered({ error }));
    })
  );

  @Effect()
  authNotAllowed$ = this.actions$.pipe(
    ofType<AuthNotAllowed>(AuthActionTypes.AuthNotAllowed),
    switchMap(() => {
      return of(new LogOut());
    }),
    catchError(error => {
      return of(new ErrorEncountered({ error }));
    })
  );

  @Effect()
  logOut$ = this.actions$.pipe(
    ofType<LogOut>(AuthActionTypes.LogOut),
    switchMap(() => {
      this.authService.logout();
      return of(new LoggedOut());
    }),
    catchError(error => {
      return of(new ErrorEncountered({ error }));
    })
  );

  @Effect()
  resetPassword = this.actions$.pipe(
    ofType<ResetPassword>(AuthActionTypes.ResetPassword),
    switchMap(() => {
      this.authService.resetPassword();
      return of(new AfterResetPassword())
    }),
    catchError(error => {
      return of(new ErrorEncountered({ error }));
    })
  )


  @Effect()
  getAuthData$ = this.actions$.pipe(
    ofType<GetAuthData>(AuthActionTypes.GetAuthData),
    switchMap(() => {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      const tenantId = environment.apiConstants.AZURE_B2C_CLIENT_ID;
      return of(new Authenticated({
        authData: AuthFactory.toModel({
          firstName: user?.first,
          lastName: user?.last,
          tenantId
        })
      }));
    }),
    catchError(error => {
      return of(new ErrorEncountered({ error }));
    })
  );

}
