import { CredentialModel } from "../models/credential.model";
import { PostCreateOpsModel } from "../models/post-create-ops.model";

export class CredentialFactory {

  static toDTO(tenantId: string, credentialModel: CredentialModel): any {
    return {
      tenant_id: tenantId,
      credential: credentialModel.credential,
      policy_id: credentialModel.credentialType,
      location: credentialModel.location,
      roles: JSON.stringify(credentialModel.roles),
      permissions: JSON.stringify(credentialModel.permissions),
      groups: JSON.stringify(credentialModel.groups),
      post_create_ops: credentialModel.postCreateOps ? JSON.stringify(credentialModel.postCreateOps.map(this.postCreateOpsToDto)) : null,
      expiry: credentialModel?.expiry ? Number(credentialModel.expiry) : null
    };
  }

  static toModel(dto: any): CredentialModel {
    return {
      credential: dto.credential,
      credentialType: dto.policy_id.toUpperCase(),
      location: dto.location,
      modifiedDate: dto.time_modified,
      roles: dto.roles ? JSON.parse(dto.roles) : null,
      permissions: (typeof dto.permissions === 'string') ? dto.permissions : JSON.parse(dto.permissions),
      groups: (typeof dto.groups === 'string') ? dto.groups : JSON.parse(dto.groups),
      expiry: dto.expiry ?? null
    };
  }

  static postCreateOpsToDto(postCreateOps: PostCreateOpsModel) {
    return {
      method: postCreateOps.method,
      url: postCreateOps.url,
      body: JSON.stringify(postCreateOps.body)
    }
  }

}
