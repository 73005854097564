import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  CreateCredential,
  CreateCredentialList,
  CredentialActionTypes,
  CredentialCreated,
  CredentialListCreated,
  CredentialsLoaded,
  DeletePolicyKind,
  ErrorEncountered,
  GetTenantConfiguration,
  LoadCredentials,
  PolicyKindDeleted,
  PolicyKindUpdated,
  TenantConfigurationLoaded,
  UpdatePolicyKind,
  ReinviteCredential,
  ReinvitedCredential,
  UpdateTenantName,
  TenantNameUpdated,
  GetTenantLocations,
  TenantLocationsLoaded,
  CreateTenantLocation,
  TenantLocationCreated,
  CredentialAlreadyAddedError
} from './credential.actions';
import { CredentialService } from '../../services/credential.service';
import { CredentialModel } from '../../models/credential.model';
import { TenantConfigurationModel, TenantLocationModel } from '../../models/tenant-configuration.model';
import { CredentialTypes } from '../../../utils/enums/credential-types.enum';
import { selectLastPage } from './credential.selector';
import { CredentialState } from './credential.reducer';


@Injectable()
export class CredentialEffects {

  constructor(private actions$: Actions, private credentialService: CredentialService, private store: Store<CredentialState>) {}

  @Effect()
  reinviteCredential$ = this.actions$.pipe(
    ofType<ReinviteCredential>(CredentialActionTypes.ReinviteCredential),
    switchMap(({payload}) => this.credentialService.resendInvite(payload.credential)
      .pipe(
        map(() => {
          return new ReinvitedCredential();
        }),
        catchError(error => {
          return of(new ErrorEncountered({error}));
        })
      )),
    catchError(error => {
      return of(new ErrorEncountered({error}));
    })
  );

  @Effect()
  loadCredentials$ = this.actions$.pipe(
    ofType<LoadCredentials>(CredentialActionTypes.LoadCredentials),
    withLatestFrom(this.store.pipe(select(selectLastPage))),
    switchMap(([_, lastPage]) => this.credentialService.list(lastPage)
      .pipe(
        map((credentials: CredentialModel[]) => {
          return new CredentialsLoaded({credentials});
        }),
        catchError(error => {
          return of(new ErrorEncountered({error}));
        })
      )),
    catchError(error => {
      return of(new ErrorEncountered({error}));
    })
  );

  @Effect()
  getTenantConfiguration$ = this.actions$.pipe(
    ofType<GetTenantConfiguration>(CredentialActionTypes.GetTenantConfiguration),
    switchMap(({payload}) => this.credentialService.getTenantConfiguration(payload.tenantId)
      .pipe(
        map((tenantConfiguration: TenantConfigurationModel) => {
          return new TenantConfigurationLoaded({tenantConfiguration});
        }),
        catchError(error => {
          return of(new ErrorEncountered({error}));
        })
      )),
    catchError(error => {
      return of(new ErrorEncountered({error}));
    })
  );

  @Effect()
  getTenantLocations$ = this.actions$.pipe(
    ofType<GetTenantLocations>(CredentialActionTypes.GetTenantLocations),
    switchMap(({payload}) => this.credentialService.getTenantLocations()
      .pipe(
        map((tenantLocations: TenantLocationModel[]) => {
          return new TenantLocationsLoaded({tenantId: payload.tenantId, tenantLocations});
        }),
        catchError(error => {
          return of(new ErrorEncountered({error}));
        })
      )),
    catchError(error => {
      return of(new ErrorEncountered({error}));
    })
  );

  @Effect()
  createCredential$ = this.actions$.pipe(
    ofType<CreateCredential>(CredentialActionTypes.CreateCredential),
    switchMap(({payload}) => this.credentialService.createCredential(payload.tenantId, payload.credentialModel)
      .pipe(
        map((credential: CredentialModel) => {
          if ((credential as any).error) {
            return new CredentialAlreadyAddedError();
          }
          return new CredentialCreated({credential});
        }),
      )),
    catchError(error => {
      return of(new ErrorEncountered({error}));
    })
  );

  @Effect()
  createCredentialList$ = this.actions$.pipe(
    ofType<CreateCredentialList>(CredentialActionTypes.CreateCredentialList),
    switchMap(({payload}) => this.credentialService.createCredentialList(payload.tenantId, payload.credentials)
      .pipe(
        map((credentials: CredentialModel[]) => {
          const createdCredentials = [];
          credentials.forEach(currentCredential => {
            if (currentCredential.credential) {
              createdCredentials.push(currentCredential);
            }
          });
          if (createdCredentials.length > 0) {
            if (createdCredentials.length !== payload.credentials.length) {
              this.store.dispatch(new CredentialAlreadyAddedError());
              return new CredentialListCreated({credentials: createdCredentials});
            } else {
              return new CredentialListCreated({credentials: createdCredentials});
            }
          } else {
            return new CredentialAlreadyAddedError();
          }
        }),
      )),
    catchError(error => {
      return of(new ErrorEncountered({error}));
    })
  );

  @Effect()
  updatePolicyKind$ = this.actions$.pipe(
    ofType<UpdatePolicyKind>(CredentialActionTypes.UpdatePolicyKind),
    switchMap(({payload}) => this.credentialService.updatePolicyList(payload.policies, payload.tenantId)
      .pipe(
        map((policies: CredentialTypes[]) => {
          return new PolicyKindUpdated({policies});
        }),
        catchError(error => {
          return of(new ErrorEncountered({error}));
        })
      )),
    catchError(error => {
      return of(new ErrorEncountered({error}));
    })
  );

  @Effect()
  updateTenantName$ = this.actions$.pipe(
    ofType<UpdateTenantName>(CredentialActionTypes.UpdateTenantName),
    switchMap(({payload}) => this.credentialService.updateTenantCommonName(payload.tenantName, payload.tenantId)
      .pipe(
        map(() => {
          return new TenantNameUpdated({tenantName: payload.tenantName});
        }),
        catchError(error => {
          return of(new ErrorEncountered({error}));
        })
      )),
    catchError(error => {
      return of(new ErrorEncountered({error}));
    })
  );

  @Effect()
  createTenantLocation$ = this.actions$.pipe(
    ofType<CreateTenantLocation>(CredentialActionTypes.CreateTenantLocation),
    switchMap(({payload}) => this.credentialService.createTenantLocation(payload.name)
      .pipe(
        map((tenantLocation) => {
          return new TenantLocationCreated({tenantLocation});
        }),
        catchError(error => {
          return of(new ErrorEncountered({error}));
        })
      )),
    catchError(error => {
      return of(new ErrorEncountered({error}));
    })
  );

  @Effect()
  deletePolicyKind$ = this.actions$.pipe(
    ofType<DeletePolicyKind>(CredentialActionTypes.DeletePolicyKind),
    switchMap(({payload}) => this.credentialService.deletePolicyList(payload.policies, payload.tenantId)
      .pipe(
        map((policies: CredentialTypes[]) => {
          return new PolicyKindDeleted({policies});
        }),
        catchError(error => {
          return of(new ErrorEncountered({error}));
        })
      )),
    catchError(error => {
      return of(new ErrorEncountered({error}));
    })
  );

}
