import { NgModule } from '@angular/core';
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { MsalModule } from "@azure/msal-angular";

import { reducers } from "./store";
import { environment } from "../../environments/environment";
import { AuthEffects } from "./store/auth/auth.effects";
import { AuthService } from "./services/auth.service";
import { CredentialService } from "./services/credential.service";
import { AuthInterceptor } from "./interceptor/auth.interceptor";
import { CredentialEffects } from "./store/credential/credential.effects";
import { UserEffects } from './store/user/user.effects';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    StoreModule.forRoot(reducers),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([AuthEffects, CredentialEffects, UserEffects]),
    MsalModule.forRoot({
      auth: {
        clientId: environment.apiConstants.AZURE_B2C_CLIENT_ID,
        authority: environment.apiConstants.AZURE_B2C_AUTHORITY,
        validateAuthority: false,
        postLogoutRedirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    }),
  ],
  providers: [
    AuthService,
    CredentialService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ]
})
export class CoreModule { }
