import { AuthActions, AuthActionTypes } from "./auth.actions";
import { AuthModel } from "../../models/auth.model";

export interface AuthState {
  auth: AuthModel;
}

export const initialState: AuthState = {
  auth: null
};

export function authReducer(state = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthActionTypes.Authenticated: {
      return {
        ...state,
        auth: action.payload.authData
      }
    }
    default:
      return state;
  }
}
