import { AuthModel } from "../models/auth.model";

export class AuthFactory { // TODO: probably worth removing as there is no validation nor type enforcing

  static toDTO(auth: AuthModel): any {
    throw Error('not implemented yet');
  }

  static toModel(dto: any): AuthModel {
    return {
      tenantId: dto.tenantId,
      firstName: dto.firstName,
      lastName: dto.lastName
    };
  }

}
