import {CanActivate, Router} from "@angular/router";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class NoAuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      this.router.navigateByUrl('/dashboard');
      return false;
    }
    return true;
  }

}
