import { Action } from '@ngrx/store';
import { CredentialModel } from "../../models/credential.model";
import { CredentialTypes } from "../../../utils/enums/credential-types.enum";
import { TenantConfigurationModel, TenantLocationModel } from "../../models/tenant-configuration.model";

export enum CredentialActionTypes {
  LoadCredentials = '[Credential] Load Credentials',
  CredentialsLoaded = '[Credential Effect] Credentials Loaded',
  GetTenantConfiguration = '[Credential] Get Tenant Configuration',
  TenantConfigurationLoaded = '[Credential Effect] Tenant Configuration Loaded',
  GetTenantLocations = '[Credential] Get Tenant Locations',
  TenantLocationsLoaded = '[Credential Effect] Tenant Locations Loaded',
  ErrorEncountered = '[Credential Effect] Error Encountered',
  CreateCredential = '[Credential] Create Credential',
  CredentialCreated = '[Credential Effect] Credential Created',
  CreateCredentialList = '[Credential] Create Credential List',
  CredentialListCreated = '[Credential Effect] Credential List Created',
  CredentialAlreadyAddedError = '[Credential] Already added Error',
  UpdatePolicyKind = '[Credential] Update Policy Kind',
  PolicyKindUpdated = '[Credential Effect] Policy Kind Updated',
  DeletePolicyKind = '[Credential] Delete Policy Kind',
  PolicyKindDeleted = '[Credential Effect] Policy Kind Deleted',
  ReinviteCredential = '[Credential] Reinvite',
  ReinvitedCredential = '[Credential Effect] Reinvite',
  UpdateTenantName = '[Credential] Update Tenant Name',
  TenantNameUpdated = '[Credential Effect] Tenant Name Updated',
  CreateTenantLocation = '[Credential] Create Tenant Location',
  TenantLocationCreated = '[Credential Effect] Tenant Location Created',
}


export class ReinvitedCredential implements Action {
  readonly type = CredentialActionTypes.ReinvitedCredential;

}

export class ReinviteCredential implements Action {
  readonly type = CredentialActionTypes.ReinviteCredential;
  constructor(public payload: { credential: string }) {}
}

export class LoadCredentials implements Action {
  readonly type = CredentialActionTypes.LoadCredentials;

}

export class CredentialsLoaded implements Action {
  readonly type = CredentialActionTypes.CredentialsLoaded;

  constructor(public payload: {credentials: CredentialModel[]}) {}
}

export class GetTenantConfiguration implements Action {
  readonly type = CredentialActionTypes.GetTenantConfiguration;

  constructor(public payload: {tenantId: string}) {}
}

export class TenantConfigurationLoaded implements Action {
  readonly type = CredentialActionTypes.TenantConfigurationLoaded;

  constructor(public payload: {tenantConfiguration: TenantConfigurationModel}) {}
}

export class GetTenantLocations implements Action {
  readonly type = CredentialActionTypes.GetTenantLocations;

  constructor(public payload: {tenantId: string}) {}
}

export class TenantLocationsLoaded implements Action {
  readonly type = CredentialActionTypes.TenantLocationsLoaded;

  constructor(public payload: {tenantId: string, tenantLocations: TenantLocationModel[]}) {}
}

export class ErrorEncountered implements Action {
  readonly type = CredentialActionTypes.ErrorEncountered;

  constructor(public payload: { error: any }) {}
}

export class CreateCredential implements Action {
  readonly type = CredentialActionTypes.CreateCredential;

  constructor(public payload: { tenantId: string, credentialModel: CredentialModel }) {}
}

export class CredentialCreated implements Action {
  readonly type = CredentialActionTypes.CredentialCreated;

  constructor(public payload: { credential: CredentialModel }) {}
}

export class CreateCredentialList implements Action {
  readonly type = CredentialActionTypes.CreateCredentialList;

  constructor(public payload: { tenantId: string, credentials: CredentialModel[] }) {}
}

export class CredentialListCreated implements Action {
  readonly type = CredentialActionTypes.CredentialListCreated;

  constructor(public payload: { credentials: CredentialModel[] }) {}
}

export class CredentialAlreadyAddedError implements Action {
  readonly type = CredentialActionTypes.CredentialAlreadyAddedError;
}

export class UpdatePolicyKind implements Action {
  readonly type = CredentialActionTypes.UpdatePolicyKind;

  constructor(public payload: { policies: CredentialTypes[], tenantId: string }) {}
}

export class PolicyKindUpdated implements Action {
  readonly type = CredentialActionTypes.PolicyKindUpdated;

  constructor(public payload: { policies: CredentialTypes[] }) {}
}

export class UpdateTenantName implements Action {
  readonly type = CredentialActionTypes.UpdateTenantName;

  constructor(public payload: { tenantName: string, tenantId: string }) {}
}

export class TenantNameUpdated implements Action {
  readonly type = CredentialActionTypes.TenantNameUpdated;

  constructor(public payload: { tenantName: string }) {}
}

export class CreateTenantLocation implements Action {
  readonly type = CredentialActionTypes.CreateTenantLocation;

  constructor(public payload: { name: string }) {}
}

export class TenantLocationCreated implements Action {
  readonly type = CredentialActionTypes.TenantLocationCreated;

  constructor(public payload: { tenantLocation: TenantLocationModel }) {}
}

export class DeletePolicyKind implements Action {
  readonly type = CredentialActionTypes.DeletePolicyKind;

  constructor(public payload: { policies: CredentialTypes[], tenantId: string }) {}
}

export class PolicyKindDeleted implements Action {
  readonly type = CredentialActionTypes.PolicyKindDeleted;

  constructor(public payload: { policies: CredentialTypes[] }) {}
}

export type CredentialActions = LoadCredentials
  | CredentialsLoaded
  | ErrorEncountered
  | CreateCredential
  | CredentialCreated
  | CreateCredentialList
  | CredentialListCreated
  | CredentialAlreadyAddedError
  | GetTenantConfiguration
  | TenantConfigurationLoaded
  | GetTenantLocations
  | TenantLocationsLoaded
  | UpdatePolicyKind
  | PolicyKindUpdated
  | DeletePolicyKind
  | PolicyKindDeleted
  | ReinviteCredential
  | ReinvitedCredential
  | UpdateTenantName
  | TenantNameUpdated
  | CreateTenantLocation
  | TenantLocationCreated;
