import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {authReducer} from "./auth/auth.reducer";
import { credentialReducer } from "./credential/credential.reducer";
import {userReducer} from './user/user.reducer';


export interface State {

}

export const reducers: ActionReducerMap<State> = {
  auth: authReducer,
  credential: credentialReducer,
  users: userReducer
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
