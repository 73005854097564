import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as UserActions from './user.actions';
import { catchError, concatMap, map, switchMap, throttle, withLatestFrom } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { forkJoin, Observable, of, timer } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { State } from '../index';
import * as UserSelectors from './user.selectors';
import { User } from '../../models/user.model';
import { UserPermissions } from '../../../utils/enums/user-permissions.enum';


@Injectable()
export class UserEffects {

  constructor(
    private userService: UserService,
    private store: Store<State>,
    private actions$: Actions
  ) {
  }

  removeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteUser),
      switchMap(({user}) => this.userService.removeUser(user.id))
    ),
    {dispatch: false}
  );

  loadPatients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadPatients),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(UserSelectors.selectPatients)))
      )),
      throttle(([_, users]) => users.length > 0? timer(2000): timer(0)),
      withLatestFrom(this.store.pipe(select(UserSelectors.selectLastPage))),
      switchMap(([_, lastPage]) => {
        return this.userService.loadPatients(lastPage).pipe(
          map(users => {
            return UserActions.patientsLoaded({users});
          })
        );
      })
    )
  );

  loadCoaches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadCoaches),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(UserSelectors.selectCoaches)))
      )),
      throttle(([_, users]) => users.length > 0? timer(2000): timer(0)),
      switchMap(() => {
        return this.userService.loadCoaches().pipe(
          map(users => UserActions.usersLoaded({users}))
        );
      })
    )
  );

  loadNurses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadNurses),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(UserSelectors.selectNurses)))
      )),
      throttle(([_, users]) => users.length > 0? timer(2000): timer(0)),
      switchMap(() => {
        return this.userService.loadNurses().pipe(
          map(users => UserActions.usersLoaded({users}))
        );
      })
    )
  );

  loadDoctors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadDoctors),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(UserSelectors.selectDoctors)))
      )),
      throttle(([_, users]) => users.length > 0? timer(2000): timer(0)),
      switchMap(() => {
        return this.userService.loadDoctors().pipe(
          map(users => UserActions.usersLoaded({users}))
        );
      })
    )
  );

  loadUnassignedCTMs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUnassignedCTMs),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(UserSelectors.selectDoctors)))
      )),
      throttle(([_, users]) => users.length > 0? timer(2000): timer(0)),
      switchMap(() => {
        return this.userService.loadUnassignedCTMs().pipe(
          map(users => UserActions.usersLoaded({users}))
        );
      })
    )
  );

  assignPatients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.assignPatientsToUsers),
      switchMap(({userIds, usersIdsToAssign}) => {
        return this.userService.assignPatientsToUsers(userIds, usersIdsToAssign);
      })
    ),
    {dispatch: false}
  );

  assignCTMs$ = createEffect(() =>
      this.actions$.pipe(
        ofType(UserActions.assignCTMStoUsers),
        switchMap(({userIds, usersIdsToAssign}) => {
          return this.userService.assignCTMsToUsers(userIds, usersIdsToAssign);
        })
      ),
    {dispatch: false}
  );

  // loadAssignedUsersForUsers$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(UserActions.loadAssignedUsersForUsers),
  //       switchMap(({userIds}) => {
  //         console.log(userIds)
  //         return of(null);
  //       })
  //     ),
  //   {dispatch: false}
  // );

  loadFullUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadFullUser),
      concatMap(action => of(action.id).pipe(
        withLatestFrom(this.store.pipe(select(UserSelectors.selectAll)))
      )),
      switchMap(([id, users]) => {
        const user = users.find(userItem => userItem.id === id);
        let userObservable: Observable<User>;
        if(user){
          userObservable = of(user);
        } else {
          userObservable = this.userService.getUser(id);
        }
        return forkJoin({
          userResult: userObservable,
          assignedUsers: this.userService.getAssignedUsers(id)
        }).pipe(
          switchMap(({userResult, assignedUsers }) => {
            if(!user){
              userResult.assignedPatients = assignedUsers.assignedPatients;
              userResult.assignedCTMs = assignedUsers.assignedCTMs;
              this.store.dispatch(UserActions.addUser({user: userResult}));
            } else {
              this.store.dispatch(UserActions.updateUser({
                user: {
                  id: user.id,
                  changes: {
                    assignedPatients: assignedUsers.assignedPatients,
                    assignedCTMs: assignedUsers.assignedCTMs
                  }
                }
              }));
            }
            return this.userService.getUserByIds(assignedUsers.assignedPatients.concat(assignedUsers.assignedCTMs))
              .pipe(
                map(users => UserActions.usersLoaded({users}))
              );
          })
        )
      })
    )
  );

  assignRoleToUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.assignRoleToUser),
      switchMap(({userIds, roleId}) => this.userService.addRoleToUsers(userIds, roleId)
        .pipe(
          map(role =>
            UserActions.updateUsers({
              users: userIds.map(id => ({id, changes: {roleNames: [role]}}))
            })
          )
        )
      )
    )
  );

  changeUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeUserRoles),
      switchMap(({user, rolesToAdd, rolesToRemove }) => {
        const currentRoles = user.roleNames;
        const datalogistRoles = user.roleNames.filter(roleName => roleName !== 'Patient');
        let permissionSetUp = of(null);
        if(currentRoles.length === 0 || currentRoles.length === 1 && currentRoles[0] === 'Patient') {
          permissionSetUp = this.userService.addPermissionToUser(user.id, UserPermissions.datalogist)
            .pipe(
              catchError(() => of([]))
            );
        } else if(rolesToAdd.length === 0 && datalogistRoles.length !== 0 && rolesToRemove.length >= datalogistRoles.length){
          permissionSetUp = this.userService.removePermissionFromUser(user.id, UserPermissions.datalogist)
            .pipe(
              catchError(() => of([]))
            );
        }
        return permissionSetUp.pipe(
          switchMap(() => {
            let removedRoles = of([]);
            let addedRoles = of([]);
            if(rolesToRemove.length > 0){
              removedRoles = forkJoin(...rolesToRemove.map(id => this.userService.removeRoleFromUser(user.id, id)));
            }
            if(rolesToAdd.length > 0){
              addedRoles = forkJoin(...rolesToAdd.map(id => this.userService.addRoleToUser(user.id, id)));
            }
            return forkJoin({
              removedRoles,
              addedRoles
            }).pipe(
              map(({removedRoles, addedRoles}) => UserActions.updateUser({
                  user: {
                    id: user.id,
                    changes: {
                      roleNames: user.roleNames
                        .filter(name => !removedRoles.includes(name))
                        .concat(addedRoles)
                    }
                  }
                })
              )
            );
          })
        );
      })
    )
  );
}
