import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from "./utils/guards/auth.guard";
import { NoAuthGuard } from "./utils/guards/no-auth.guard";


const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./components/sections/dashboard/dashboard.module').then(mod => mod.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () => import('./components/sections/auth/auth.module').then(mod => mod.AuthModule),
    canActivate: [NoAuthGuard]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
