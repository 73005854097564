import { CredentialModel } from "../../models/credential.model";
import { CredentialActions, CredentialActionTypes } from "./credential.actions";
import { TenantConfigurationModel, TenantLocationModel } from "../../models/tenant-configuration.model";
import { CredentialTypes } from "../../../utils/enums/credential-types.enum";

export interface CredentialState {
  credentials?: CredentialModel[],
  configuration?: TenantConfigurationModel,
  locations?: TenantLocationModel[],
  lastPage: number,
  loadedAll: boolean
}

export const initialState: CredentialState = {
  lastPage: -1,
  loadedAll: false
};

export function credentialReducer(state = initialState, action: CredentialActions): CredentialState {
  switch (action.type) {
    case CredentialActionTypes.CredentialsLoaded: {
      const newLastPage = state.lastPage + 1;
      const loadedAll = !action.payload.credentials.length;
      let newCredentials = [];
      if (state.credentials) {
        newCredentials = [...state.credentials, ...action.payload.credentials];
      } else {
        newCredentials = [...action.payload.credentials]
      }
      return {
        ...state,
        credentials: newCredentials,
        lastPage: newLastPage,
        loadedAll
      }
    }
    case CredentialActionTypes.TenantConfigurationLoaded: {
      return {
        ...state,
        configuration: action.payload.tenantConfiguration
      }
    }
    case CredentialActionTypes.TenantLocationsLoaded: {
      const locations: TenantLocationModel[] = action.payload.tenantLocations
        .filter(location => location.tenantId === action.payload.tenantId);
      return {
        ...state,
        locations: locations
      }
    }
    case CredentialActionTypes.PolicyKindUpdated: {
      const currentPolicies: CredentialTypes[] = [...state.configuration.policies];
      const newPolicies = action.payload.policies.filter(policy => !currentPolicies.includes(policy));
      return {
        ...state,
        configuration: {
          ...state.configuration,
          policies: [...currentPolicies, ...newPolicies]
        }
      }
    }
    case CredentialActionTypes.PolicyKindDeleted: {
      const currentPolicies: CredentialTypes[] = [...state.configuration.policies];
      const updatedPolicies = currentPolicies.filter(policy => !action.payload.policies.includes(policy));
      return {
        ...state,
        configuration: {
          ...state.configuration,
          policies: updatedPolicies
        }
      }
    }
    case CredentialActionTypes.CredentialCreated: {
      const credentials = [...state.credentials];
      credentials.unshift(action.payload.credential);
      return {
        ...state,
        credentials: credentials
      }
    }
    case CredentialActionTypes.CredentialListCreated: {
      let newCredentials: CredentialModel[];
      if (state.credentials) {
        newCredentials = [...state.credentials, ...action.payload.credentials];
      } else {
        newCredentials = [...action.payload.credentials]
      }
      return {
        ...state,
        credentials: newCredentials
      }
    }
    case CredentialActionTypes.TenantNameUpdated: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          tenantName: action.payload.tenantName
        }
      }
    }
    case CredentialActionTypes.TenantLocationCreated: {
      return {
        ...state,
        locations: [
          ...state.locations,
          action.payload.tenantLocation
        ]
      }
    }
    default:
      return state;
  }
}
