import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { User } from '../../models/user.model';
import * as UserActions from './user.actions';

export const usersFeatureKey = 'users';

export interface State extends EntityState<User> {
  lastPage: number,
  loadedAll: boolean
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialState: State = adapter.getInitialState({
  lastPage: -1,
  loadedAll: false
});


export const userReducer = createReducer(
  initialState,
  on(UserActions.addUser,
    (state, action) => adapter.addOne(action.user, state)
  ),
  on(UserActions.upsertUser,
    (state, action) => adapter.upsertOne(action.user, state)
  ),
  on(UserActions.addUsers,
    (state, action) => adapter.addMany(action.users, state)
  ),
  on(UserActions.upsertUsers,
    (state, action) => adapter.upsertMany(action.users, state)
  ),
  on(UserActions.updateUser,
    (state, action) => adapter.updateOne(action.user, state)
  ),
  on(UserActions.updateUsers,
    (state, action) => adapter.updateMany(action.users, state)
  ),
  on(UserActions.deleteUser,
    (state, action) => adapter.removeOne(action.user.id, state)),
  on(UserActions.deleteUsers,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(UserActions.usersLoaded, (state, action) => {
    let users = action.users;
    const existingIds = (state.ids as number[]).filter(id => users.find(user => user.id === id));
    users = users.map(user => {
      if(existingIds.includes(user.id)){
        const roles = new Set<'Patient' | 'Coach' | 'Nurse' | 'Doctor'>();
        if (user.roleNames?.length > 0)  {
          roles.add(user.roleNames[0]);
        }
        state.entities[user.id].roleNames.forEach(role => roles.add(role));
        return {
          ...user,
          roleNames: Array.from(roles)
        }
      }
      return user
    });
    return adapter.upsertMany(users, state)
  }),
  on(UserActions.patientsLoaded, (state, action) => {
    const newLastPage = state.lastPage + 1;
    let users = action.users;
    const loadedAll = !action.users.length;
    const existingIds = (state.ids as number[]).filter(id => users.find(user => user.id === id));
    users = users.map(user => {
      if(existingIds.includes(user.id)){
        const roles = new Set<'Patient' | 'Coach' | 'Nurse' | 'Doctor'>();
        if (user.roleNames?.length > 0)  {
          roles.add(user.roleNames[0]);
        }
        state.entities[user.id].roleNames.forEach(role => roles.add(role));
        return {
          ...user,
          roleNames: Array.from(roles)
        }
      }
      return user
    });
    return {
      ...adapter.upsertMany(users, state),
      lastPage: newLastPage,
      loadedAll
    }
  }),
  on(UserActions.clearUsers,
    state => adapter.removeAll(state)
  ),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
