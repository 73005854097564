import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, map, mergeMap, toArray} from 'rxjs/operators';
import {forkJoin, from, Observable, of} from 'rxjs';
import { environment } from '../../../environments/environment';
import { CredentialTypes } from '../../utils/enums/credential-types.enum';
import { CredentialFactory } from '../factory/credential.factory';
import { CredentialModel } from '../models/credential.model';
import { TenantConfigurationFactory } from '../factory/tenant-configuration.factory';
import { TenantLocationFactory } from '../factory/tenant-location.factory';
import { TenantLocationModel } from '../models/tenant-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class CredentialService {

  constructor(private http: HttpClient) { }

  list(page: number): Observable<CredentialModel[]> {
    return this.http.get<any[]>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/tenant/credentials/${page+1}`
    ).pipe(
      map(responseList => {
      return responseList.map(CredentialFactory.toModel);
    }));
  }

  getTenantConfiguration(tenantId: string): Observable<any> {
    return this.http.get<any[]>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/tenant/config/${tenantId}/1.0`
    ).pipe(
      map(TenantConfigurationFactory.toModel)
    );
  }

  getTenantLocations(): Observable<TenantLocationModel[]> {
    return this.http.get<any[]>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/tenant_location`
    ).pipe(
      map(responseList => {
      return responseList.map(TenantLocationFactory.toModel);
    }));
  }

  createCredential(tenantId: string, credentialModel: CredentialModel): Observable<CredentialModel> {
    return this.http.post<any>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/tenant/credential`,
      CredentialFactory.toDTO(tenantId, credentialModel)
    ).pipe(
      map(CredentialFactory.toModel),
      catchError(error => of(error))
    );
  }

  resendInvite(credential: string): Observable<CredentialModel> {
    return this.http.post<any>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/tenant/credential/${credential}/resend`,
      {}
    );
  }

  createCredentialList(tenantId: string, credentialList: CredentialModel[]): Observable<CredentialModel[]> {
    return from(credentialList).pipe(
      mergeMap((credential) => this.createCredential(tenantId, credential)),
      toArray()
    );
  }

  updatePolicyList(policies: CredentialTypes[], tenantId: string): Observable<CredentialTypes[]> {
    const observableList: Observable<CredentialTypes>[] = [];
    policies.forEach(policy => observableList.push(this.updateTenantPolicyKind(policy, tenantId)));
    return forkJoin(observableList);
  }

  updateTenantPolicyKind(policyKind: CredentialTypes, tenantId: string): Observable<CredentialTypes> {
    return this.http.post<any>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/tenant/config/${tenantId}/policy`,
      { policy_id: policyKind }
    ).pipe(
      map(() => policyKind)
    );
  }

  updateTenantCommonName(commonName: string, tenantId: string): Observable<string> {
    return this.http.post<any>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/tenant/${tenantId}`,
      { common_name: commonName }
    ).pipe(
      map(() => commonName)
    );
  }

  createTenantLocation(name: string): Observable<TenantLocationModel> {
    return this.http.post<any>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/tenant_location`,
      { name: name }
    ).pipe(
      map((location) => location)
    );
  }

  deletePolicyList(policies: CredentialTypes[], tenantId: string): Observable<CredentialTypes[]> {
    const observableList: Observable<CredentialTypes>[] = [];
    policies.forEach(policy => observableList.push(this.deleteTenantPolicyKind(policy, tenantId)));
    return forkJoin(observableList);
  }

  deleteTenantPolicyKind(policyKind: CredentialTypes, tenantId: string): Observable<CredentialTypes> {
    return this.http.delete<any>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.HEALTHCLOUD_API_URL}/tenant/config/${tenantId}/policy/${policyKind}`,
    ).pipe(
      map(() => policyKind)
    );
  }

}
