import { AuthenticationParameters } from "msal";
import { Observable, from } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { MsalService } from "@azure/msal-angular";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private msalService: MsalService) { }

  login(): Observable<string> {
    return from(this.getAzureToken()).pipe(
      switchMap(azureToken => {
        return this.getJHToken(azureToken);
      })
    );
  }

  logout() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    this.deleteAllCookies();
    this.msalService.logout();
  }

  resetPassword() {
    this.msalService.authority = `${environment.apiConstants.AZURE_B2C_RESETPASSWORD}`;
    this.msalService.loginRedirect()
  }

  private deleteAllCookies() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.' +
        window.location.hostname.split('.').reverse()[1] + '.' +
        window.location.hostname.split('.').reverse()[0];
    }
  }

  private getJHToken(azureToken: string): Observable<string> {
    return this.http.post<any>(
      `${environment.apiConstants.GATEWAY_BASE_URL}${environment.apiConstants.USER_V2_API_URL}/users/@me/tokens?prms=tenant_admin`,
      {},
      {
        headers: {
          Authorization: `Bearer ${azureToken}`,
        }
      }
    ).pipe(
      map(response => response.token)
    );
  }

  private getAzureToken(): Promise<string> {
    const loginRequest: AuthenticationParameters = {
      scopes: [environment.apiConstants.AZURE_B2C_SCOPE, 'openid'],
    };
    return this.msalService.loginPopup(loginRequest).then(result => {
      return result.idToken.rawIdToken.toString()
    });
  }
}
