import { TenantLocationModel } from "../models/tenant-configuration.model";

export class TenantLocationFactory {
  static toModel(dto: any): TenantLocationModel {
    return {
      id: dto.id,
      tenantId: dto.tenant_id,
      name: dto.name,
      modifiedDate: dto.time_modified,
      createdDate: dto.time_created,
      removedDate: dto.time_removed
    };
  }
}
